:root {
  --bclr: #008e97;
}
*{
  font-family: Helvetica, Arial, sans-serif !important;
  font-smooth: auto !important;
}
/* NavBar */
.main-nav{
  background-color: var(--bclr) !important;
}
.css-cjctlb-MuiButtonBase-root-MuiTab-root{
  color: white !important;
  opacity: 1 !important;
}

.selectdrop {
  height: 55px !important;
  background-color: red !important;
}
.css-10hburv-MuiTypography-root {
  text-decoration: none !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.54);
}
.css-1542sqp-MuiButtonBase-root-MuiButton-root {
  background-color: var(--bclr) !important;
}
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  background-color: var(--bclr) !important;
}
.css-xsqe0f-MuiButtonBase-root-MuiButton-root {
  color: var(--bclr) !important;
  border-color: var(--bclr) !important;
}
.css-se6t7u-MuiButtonBase-root-MuiButton-root {
  color: var(--bclr) !important;
  border-color: var(--bclr) !important;
}

.login-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
}
.css-pc64jl-MuiTableCell-root.MuiTableCell-head {
  background-color: var(--bclr) !important;
  font-weight: 700;
  font-size: 15px;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  color: white !important;
  border-color: white !important;
}
.css-19micn4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--bclr) !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: 
  (--bclr) !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: var(--bclr) !important;
  font-weight: 700 !important;
}
.css-xuqumj-MuiButtonBase-root-MuiButton-root {
  background-color: var(--bclr) !important;
}



.table-body-loader {
  height: 300px;
  position: relative;
}
.table-body-loader div {
  position: absolute;
  top: 30%;
  left: 47%;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--bclr) !important;
}



.detailsvender{
background-color: var(--bclr) !important;
  color: white !important;
}
.vender-name{
  font-size: min(max(24px, 4vw), 39px) !important;
   font-weight: 700;
}
.home-title-name{
  font-size: min(max(24px, 4vw), 30px) !important;
  font-weight: 600;
}
.upload-button{
    margin-left: 1rem !important;
font-weight:600 !important;
}
.upload-item-h2{
  font-size: min(max(24px, 3vw), 27px) !important;

}
.file-uploadcard{
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center
}
.css-yfo96e{
  padding: 0 !important;
}

.css-1ygcj2i-MuiTableCell-root {
  background-color: var(--bclr);
  color: white !important;
 font-weight: 700 !important;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
 margin-top: 58px !important;
}


/* Loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--bclr);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* _______________________________ */

.css-t3ipsp-control{
  height: 56px !important;
}
.content-reg-div{
  background-color:var(--bclr);
  height: 50px;
  margin: -16px;
  margin-bottom: 7px
  
}
.content-reg-div h5{
  color: #ffff;
  padding-top: 13px;
  padding-left: 15px;
}